import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AccountSubscriptionType, StripeCreateType } from '@sentinels/enums';
import {
  CancelSubscriptionContract,
  CreateInvoiceContract,
  CreatePaymentIntent,
  CreateStripeCheckoutSessionContract,
  CreateStripePriceContract,
  CreateStripeProductContract,
  CreateSubscriptionContract,
  LinkOrCreateStripeAccountContract,
  StripeConnectOAuthResponse,
  StripeProduct,
  StripeProductPrice,
  StripeProductTransaction,
  UpdateStripePriceContract,
  VoidInvoiceContract,
} from '@sentinels/interfaces/stripe';
import { PaymentIntent } from '@stripe/stripe-js';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class StripeService {
  constructor(private functions: AngularFireFunctions) {}

  async getOrCreateCustomer(): Promise<string | null> {
    const data = {};
    const getOrCreateCustomer = this.functions.httpsCallable('stripe-fns-getOrCreateCustomer');
    return await getOrCreateCustomer(data)
      .toPromise()
      .catch((error) => {
        console.error(`Something went wrong getting or creating stripe customer`, error);
      });
  }

  async createPaymentIntent(
    data: CreatePaymentIntent,
  ): Promise<(PaymentIntent & { metadata?: { [key: string]: string } }) | null> {
    try {
      const createPaymentIntent = this.functions.httpsCallable(
        'stripe-fns-createPaymentIntent',
      );
      return await createPaymentIntent(data)
        .toPromise()
        .then((res: unknown) => {
          if (res['httpsErrorCode'] || res['httpErrorCode']) {
            throw res;
          }

          return res as PaymentIntent & { metadata?: { [key: string]: string } };
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      console.error(`Something went wrong creating payment intent`, error);
      return null;
    }
  }

  async createSubscription(data: CreateSubscriptionContract): Promise<unknown> {
    try {
      const createSubscription = this.functions.httpsCallable('stripe-fns-createSubscription');
      return await createSubscription(data)
        .toPromise()
        .then((res: { ok: boolean; subscription?: unknown; session?: unknown }) => {
          if (!res.ok) throw res;

          return res;
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      console.error(`Something went wrong creating subscription`, error);
      return null;
    }
  }

  async linkOrCreateStripeAccount(
    data: LinkOrCreateStripeAccountContract,
  ): Promise<string | null> {
    try {
      const linkOrCreateStripeAccount = this.functions.httpsCallable('stripe-fns-createLink');
      return await linkOrCreateStripeAccount(data)
        .toPromise()
        .then((res: unknown) => {
          if (res['httpsErrorCode']) {
            throw res;
          }

          return (<{ path: string }>res).path;
        })
        .catch((error: unknown) => {
          throw error;
        });
    } catch (error: unknown) {
      // eslint-disable-next-line max-len
      console.error(
        `Something went wrong ${
          data.type === StripeCreateType.NEW ? 'creating' : 'linking'
        } stripe account`,
        error,
      );
      return null;
    }
  }

  async unlinkStripeAccount(): Promise<boolean> {
    try {
      const unlinkStripeAccount = this.functions.httpsCallable('stripe-fns-unlink');
      return await unlinkStripeAccount({})
        .toPromise()
        .then((res: unknown) => {
          if (res['httpsErrorCode']) {
            throw res;
          }

          console.debug(`Successfully unlinked stripe account`, res);
          return (<{ deleted: boolean }>res).deleted;
        })
        .catch((error: unknown) => {
          throw error;
        });
    } catch (error: unknown) {
      console.error(`Something went wrong unlinking stripe account`, error);
      return false;
    }
  }

  async getAccount(id: string) {
    try {
      const getAccount = this.functions.httpsCallable('stripe-fns-getInfo');
      return await getAccount({ id })
        .toPromise()
        .then((res: unknown) => {
          if (res['httpsErrorCode']) {
            throw res;
          }

          return (<{ data: any }>res).data;
        })
        .catch((error: unknown) => {
          throw error;
        });
    } catch (error: unknown) {
      console.error(`Something went wrong retrieving stripe account`, error);
      return null;
    }
  }

  async connectOAuth(code: string) {
    try {
      const connectOAuth = this.functions.httpsCallable('stripe-fns-connectOAuth');
      return await connectOAuth({ code })
        .toPromise()
        .then((res: StripeConnectOAuthResponse) => {
          if (!res.ok) {
            throw res;
          }

          return res;
        })
        .catch((error: unknown) => {
          throw error;
        });
    } catch (error: unknown) {
      console.error(
        (<Error>error).message ?? 'Something went wrong connecting stripe oauth account',
      );
      return null;
    }
  }

  async cancelSubscription(
    type: AccountSubscriptionType,
    id: string,
    platform: 'gth' | 'meh',
  ) {
    try {
      const cancelSubscription = this.functions.httpsCallable('stripe-fns-cancelSubscription');
      const contract: CancelSubscriptionContract = { type, id, platform };
      return await cancelSubscription(contract)
        .pipe(take(1))
        .toPromise()
        .then((res: { ok: true; deletedSubscription?: unknown }) => {
          if (!res.ok) throw res;

          return res.ok;
        })
        .catch((error: unknown) => {
          throw error;
        });
    } catch (error: unknown) {
      // eslint-disable-next-line max-len
      console.error(
        (<Error>error).message ?? `Something went wrong cancelling subscription`,
        error,
      );
      return false;
    }
  }

  async createAndSendInvoice(contract: CreateInvoiceContract) {
    try {
      const sendInvoice = this.functions.httpsCallable('stripe-fns-createAndSendInvoice');
      return await sendInvoice(contract)
        .pipe(take(1))
        .toPromise()
        .then((res: { ok: true }) => {
          if (!res.ok) throw res;

          return res.ok;
        })
        .catch((error: unknown) => {
          throw error;
        });
    } catch (error: unknown) {
      // eslint-disable-next-line max-len
      console.error(
        (<Error>error).message ?? `Something went wrong creating and sending invoice.`,
        error,
      );
      return false;
    }
  }

  async voidInvoice(invoiceId: string) {
    try {
      const voidInvoice = this.functions.httpsCallable('stripe-fns-voidInvoice');
      const contract: VoidInvoiceContract = { id: invoiceId };
      return await voidInvoice(contract)
        .pipe(take(1))
        .toPromise()
        .then((res: { ok: true }) => {
          if (!res.ok) throw res;

          return res.ok;
        })
        .catch((error: unknown) => {
          throw error;
        });
    } catch (error: unknown) {
      // eslint-disable-next-line max-len
      console.error((<Error>error).message ?? `Something went wrong voiding invoice.`, error);
      return false;
    }
  }

  async createCheckoutSession(
    data: CreateStripeCheckoutSessionContract,
  ): Promise<string | null> {
    try {
      const createCheckoutSession = this.functions.httpsCallable(
        'stripe-fns-createCheckoutSession',
      );
      return await createCheckoutSession(data)
        .pipe(take(1))
        .toPromise()
        .then((res: unknown) => {
          if (res['httpsErrorCode'] || res['httpErrorCode']) {
            throw res;
          }

          return res as string;
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      console.error(`Something went wrong creating checkout session`, error);
      return null;
    }
  }

  async createProduct(data: CreateStripeProductContract): Promise<StripeProduct | null> {
    try {
      const createProduct = this.functions.httpsCallable('stripe-fns-createProduct');
      const res = await firstValueFrom(createProduct(data));

      if (res['httpsErrorCode'] || res['httpErrorCode']) {
        throw res;
      }
      return res as StripeProduct;
    } catch (error) {
      console.error('Something went wrong creating product', error);
      throw error;
    }
  }

  async createPrice(data: CreateStripePriceContract): Promise<StripeProductPrice | null> {
    try {
      const createPrice = this.functions.httpsCallable('stripe-fns-createPrice');
      const res = await firstValueFrom(createPrice(data));

      if (res['httpsErrorCode'] || res['httpErrorCode']) {
        throw res;
      }
      return res as StripeProductPrice;
    } catch (error) {
      console.error('Something went wrong creating price', error);
      throw error;
    }
  }

  async updatePrice(data: UpdateStripePriceContract): Promise<StripeProductPrice | null> {
    try {
      const updatePrice = this.functions.httpsCallable('stripe-fns-updatePrice');
      const res = await firstValueFrom(updatePrice(data));

      if (res['httpsErrorCode'] || res['httpErrorCode']) {
        throw res;
      }
      return res as StripeProductPrice;
    } catch (error) {
      console.error('Something went wrong updating price', error);
      throw error;
    }
  }

  async getProductTransactions({
    productId,
    lastId,
    stripeAccountId,
  }: {
    productId: string;
    lastId?: string;
    stripeAccountId?: string;
  }) {
    const getProductTransactionsFn = this.functions.httpsCallable(
      'stripe-fns-getProductTransactions',
    );
    const res = await firstValueFrom(
      getProductTransactionsFn({ productId, startingAfter: lastId, stripeAccountId }),
    );
    if (res['httpsErrorCode'] || res['httpErrorCode']) {
      throw res;
    }
    let transactions = res.transactions as StripeProductTransaction[];
    if (res.hasMore) {
      const nextTransactions = await this.getProductTransactions({
        productId,
        lastId: res.lastId,
        stripeAccountId,
      });
      transactions = [...transactions, ...nextTransactions];
    }

    return transactions; // TODO: change type to StripeProductTransaction[]
  }
}
